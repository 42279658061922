import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Pagination,
  Table,
} from "semantic-ui-react";
import { AdminsDropdown, StatusDropdown } from "../../../components";
import { ShowingsAction } from "../../../redux/actions";
import { Link, Route, Routes } from "react-router-dom";
import { DROPDOWN_OPTIONS } from "../../../constants";
import moment from "moment";
import "moment/locale/ru";
import ShowingForm from "./form";
moment.locale("ru");

const ShowingsIndex = () => {
  const [params, setParams] = useState({
    page_size: 10,
    archived: true,
  });

  useEffect(() => {
    ShowingsAction.find(params);
  }, [params]);

  const showings = useSelector((state) => state.showing).list;

  return (
    <>
      <Header dividing>
        <Button
          as={Link}
          to="/clients/showings/add"
          floated="right"
          content="Добавить"
          icon="add"
        />
        <Icon name="eye" />
        <Header.Content>
          Показы
          <Header.Subheader>
            Количество показов {showings.count}
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Form>
        <Form.Group widths={"equal"}>
          <Form.Input
            label="Телефон"
            placeholder="Введите телефон"
            onChange={(e) => setParams({ ...params, phone: e.target.value })}
            value={params.phone}
          />
          <Form.Field>
            <label>Брокер</label>
            <AdminsDropdown
              role="all"
              onChange={(broker) => setParams({ ...params, broker })}
              value={params.broker}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      {showings.results && (
        <Table celled fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Клиент</Table.HeaderCell>
              <Table.HeaderCell width={2}>Поселок</Table.HeaderCell>
              <Table.HeaderCell width={2}>Брокер</Table.HeaderCell>
              <Table.HeaderCell width={2}>Проведен</Table.HeaderCell>
              <Table.HeaderCell width={2}>Статус</Table.HeaderCell>
              <Table.HeaderCell width={2}>Дата</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {showings.results.map((showing) => (
              <Table.Row key={showing.id}>
                <Table.Cell>
                  <Link to={`/clients/info/${showing.client}`}>
                    {showing.client_phone}
                  </Link>
                </Table.Cell>
                <Table.Cell>{showing.village_name}</Table.Cell>
                <Table.Cell>{showing.broker_name}</Table.Cell>
                <Table.Cell>
                  {showing.completed_date
                    ? moment(showing.completed_date).format("LLL")
                    : "Нет"}
                </Table.Cell>
                <Table.Cell>{showing.status_title}</Table.Cell>
                <Table.Cell>
                  {moment(showing.created_at).format("LLL")}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    as={Link}
                    to={`/clients/showings/${showing.id}`}
                    icon="eye"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell className={"overflowVisible"}>
                <Dropdown
                  selection
                  onChange={(e, { value }) =>
                    setParams({ ...params, page_size: value })
                  }
                  value={params.page_size}
                  options={DROPDOWN_OPTIONS}
                />
              </Table.Cell>
              <Table.Cell textAlign="right" colspan={4}>
                {params.page_size > 0 && (
                  <Pagination
                    onPageChange={(page, { activePage }) =>
                      setParams({ ...params, page: activePage })
                    }
                    totalPages={Math.ceil(showings.count / params.page_size)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      <Routes>
        <Route path="/:id" element={<ShowingForm />} />
      </Routes>
    </>
  );
};

export default ShowingsIndex;
