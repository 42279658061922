import React from "react";
import { Dropdown } from "semantic-ui-react";
import { ROLES_OPTIONS } from "../constants";

export default ({onChange, value}) => {

    return(
        <Dropdown 
            placeholder='Выберите роль'
            value={value}
            selection
            onChange={(e, { value }) => onChange(value)}
            options={ROLES_OPTIONS} />
    )
}