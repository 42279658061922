const initialState = {
    list: {}
  }
    
export default function(state = initialState, action) {
    switch(action.type){
    case "OBJECTS_INDEX":
        return { ...state,  list: action.payload }
    default: {
            return state
        }
    }
}
    