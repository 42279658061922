import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Divider, Form, Header, Segment, TextArea } from "semantic-ui-react";
import api from "../../../api";

const Comments = () => {
    const [comment, setComment] = useState("")
    const [comments, setComments] = useState({
        results: []
    })
    const {id} = useParams()
    const user = useSelector(state => state.auth)

    const getComments = async () => {
        const response = await api.get("comments.json", {client: id, page_size: 10000})
        if (response.ok) {
            setComments(await response.json())
        }
    }
    useEffect(() => {
        getComments()
    }, [])

    const sendComment = async () => {
        const resposne = await api.post("comments.json", {client: id, comment, user: user.id})
        if (resposne.ok) {
            getComments()
            setComment("")
        }
    }
    return (
        <>
            <Header
                content="Комментарии клиента"
                subheader="Ваши комментарии по клиенту"
                dividing/>
            {
                comments.results.map(com => 
                    <Segment>
                        {com.comment}
                    </Segment>
                    )
            }
            <Divider/>
            <Form>
                <Form.Field>
                    <label>Комментарий</label>
                    <TextArea
                        value={comment}
                        placeholder={"Введите комментарий"}
                        onChange={(e) => setComment(e.target.value)}/>
                </Form.Field>
                <Form.Field>
                    <Button
                        content="Отправить"
                        color="green"
                        onClick={sendComment}/>
                </Form.Field>
            </Form>
            
        </>
    )
}
export default Comments