import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Loader, Menu } from "semantic-ui-react";
import { ObjectAction } from "../../redux/actions";
import Info from './details/info'
import Areas from './details/areas'

const Details = () => {
    const [activePage, setActivePage] = useState("info")
    const [object, setObject] = useState({})

    const {id} = useParams()

    const getObject = async () => {
        const response = await ObjectAction.get(id)
        if (response.ok) {
            setObject(await response.json())
        }
    }

    useEffect(() => {
        getObject()
    }, [])

    return (
        
            object.id ? 
            <>
                <Header
                    content={`Объект ${object.name}`}
                    icon='warehouse'/>
                <Menu widths={2} >
                    <Menu.Item
                        content="Информация об объекте"
                        onClick={() => setActivePage('info')}
                        active={activePage === 'info'}/>
                    <Menu.Item
                        content="Участки"
                        onClick={() => setActivePage('areas')}
                        active={activePage === 'areas'}/>
                </Menu>
                {
                    activePage === 'info' &&
                    <Info object={object} onChange={getObject}/>
                }
                {
                    activePage === 'areas' &&
                    <Areas object={object}/>
                }
            </>
            :
            <Loader active/>
        
        
    )
}

export default Details