import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { AuthAction } from "../../redux/actions";
import { toast } from "react-toastify";

export default () => {
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    const navigate = useNavigate()

    const submit = async () => {
        const resposne = await AuthAction.register(form)
        if (!resposne.ok){
            setErrors(await resposne.json())
        } else{
            toast.success("Регистрация успешна")
            navigate('/auth/login')
        }
    }
    return (
        <Form onSubmit={submit}>
            <Form.Input
                required
                label="Почта"
                error={errors.username}
                placeholder="Введите почту"
                onChange={(e) => setForm({...form, username: e.target.value.toLowerCase()})}/>
            <Form.Input
                label="Пароль"
                required
                placeholder="Введите пароль"
                error={errors.password}
                type="password"
                onChange={(e) => setForm({...form, password: e.target.value})}/>
            <Form.Input
                label="Имя"
                required
                placeholder="Введите имя"
                onChange={(e) => setForm({...form, first_name: e.target.value})}/>
            <Form.Input
                required
                label="Фамилия"
                placeholder="Введите фамилию"
                onChange={(e) => setForm({...form, last_name: e.target.value})}/>
            <Form.Input
                label="Телефон"
                placeholder="Введите телефон"
                onChange={(e) => setForm({...form, phone: e.target.value})}/>
            <Button
                as={Link}
                to="/auth/login"
                content="Войти"/>
            <Button
                color="green"
                type="submit"
                content="Зарегистрироваться"/>
        </Form>
    )

}