import store from '../store'
import api from '../../api'

export default class Actions {

    static async find(params){

        let response = await api.get("bookings.json", params)
        if (response.ok) {
          store.dispatch({
            type: "BOOKINGS_INDEX",
            payload: await response.json()
          })
        }
    }
    static async delete(id) {
      let response = await api.delete(`bookings/${id}.json`)
      return response
    }
    static async renew(id) {
      let response = await api.post(`bookings/${id}/renew.json`)
      return response
    }


}