import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import api from "../api";
import { CLIENT_STATUS_OPTIONS } from "../constants";
export default ({value, onChange}) => {




    return (
        <Dropdown
            fluid
            search
            selection
            clearable
            value={value}
            onChange={(e, { value }) => onChange(value)}
            options={CLIENT_STATUS_OPTIONS}
            placeholder="Начните вводить"
            noResultsMessage="Ничего не найдено"
            selectOnBlur={false}/>
    )
}