import store from '../store'
import api from '../../api'
import { toast } from 'react-toastify'

export default class Actions {

    static async find(params){

    let response = await api.get("users.json", params)
        if (response.ok) {
          store.dispatch({
            type: "ADMINS_INDEX",
            payload: await response.json()
          })
        }
    }
    static async update(data){
      let response = await api.put(`users/${data.id}.json?role=admin`, data)
      if (response.ok){
        Actions.find()
        toast.success("Админ обновлен")
      }
      return response
    }
}