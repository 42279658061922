import store from '../store'
import api from '../../api'
import { toast } from 'react-toastify'

export default class Actions {

    static async find(params){
        let response = await api.get("areas.json", params)
        if (response.ok) {
          store.dispatch({
            type: "AREAS_INDEX",
            payload: await response.json()
          })
        }
    }

    static async uploadFile(form) {
      const data = new FormData()
      for (let i in form) {
        data.append(i, form[i])
      }
      let response = await api.post("areas_documents.json", data, undefined)
      return response
    }

    static async deleteFile(doc) {
      const response = await api.delete(`areas_documents/${doc.id}.json`)
      return response
    }
    static async book(doc) {
      const response = await api.post(`areas/${doc.id}/book.json`, doc)
      return response
    }

    static async update(data){
      let response = await api.put(`areas/${data.id}.json`, data)
      return response
    }

    static async get(id){
      let response = await api.get(`areas/${id}.json`)
      return response
    }
}