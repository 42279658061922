import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { AuthAction } from "../../redux/actions";
export default () => {
    const [form, setForm] = useState()

    const navigate = useNavigate()
    
    const submit = async () => {
        const response = await AuthAction.login(form)
        if (response.ok){
            navigate('/')
        }
    }
    return (
        <Form onSubmit={submit}>
            <Form.Input
                label="Почта"
                onChange={(e) => setForm({...form, username: e.target.value.toLowerCase()})}/>
            <Form.Input
                type="password"
                label="Пароль"
                onChange={(e) => setForm({...form, password: e.target.value})}/>
            <Button
                as={Link}
                to="/auth/register"
                content="Создать аккаунт"/>
            <Button
                color="green"
                type="submit"
                content="Войти"/>
        </Form>
    )
}