import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Header } from "semantic-ui-react";
import { UserAction } from "../../redux/actions";

const Index = () => {
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const submit = async () => {
        const response = await UserAction.updateInfo(form)
        if (response.ok) {
            toast.success("Профиль сохранен")
        }else {
            setErrors(await response.json())
        }
    }

    const getUserInfo = async () => {
        const data = await UserAction.info()
        setForm(data)
    }

    useEffect(() => {
        getUserInfo()
    }, [])

    return (
        <>
            <Header
                content={'Редактировать профиль'}
                subheader={'Введите измененные данные'}/>
            <Form onSubmit={submit} noValidate>
                <Form.Input
                    required
                    label="Почта"
                    value={form.username}
                    error={errors.username}
                    placeholder="Введите почту"
                    onChange={(e) => setForm({...form, username: e.target.value.toLowerCase()})}/>
                <Form.Input
                    label="Имя"
                    value={form.first_name}
                    placeholder="Введите имя"
                    onChange={(e) => setForm({...form, first_name: e.target.value})}/>
                <Form.Input
                    label="Фамилия"
                    value={form.last_name}
                    placeholder="Введите фамилию"
                    onChange={(e) => setForm({...form, last_name: e.target.value})}/>
                <Form.Input
                    label="Телефон"
                    value={form.phone}
                    placeholder="Введите телефон"
                    onChange={(e) => setForm({...form, phone: e.target.value})}/>
                <Button
                    color="green"
                    type="submit"
                    content="Сохранить"/>
            </Form>
        </>
        
    )
}

export default Index