import store from '../store'
import api from '../../api'
import { toast } from 'react-toastify'

export default class Actions {

    static async find(params){

      let response = await api.get("users.json", {...params, role: 'broker'})
        if (response.ok) {
          store.dispatch({
            type: "BROKERS_INDEX",
            payload: await response.json()
          })
        }
    }
    static async update(data){
      let response = await api.put(`users/${data.id}.json?role=broker`, data)
      if (response.ok){
        Actions.find({accepted: true})
        toast.success("Брокер обновлен")
      }
      return response
    }

    static async acceptBroker (data){
      let response = await api.post(`users/${data.id}/accept.json?role=broker`, data)
      if (response.ok){
        Actions.find({accepted: false})
      }
      return response
    }
    static async tgMessage (message) {
      const data = new FormData()
      
      data.append('text', message['text'])
      console.log(message.files)
      for (let file of message['files']) {
        
        data.append('files[]', file)
      }
      let response = await api.post("users/tg_message.json", data)
      return response
    }
    static async uploadFile(form) {
      const data = new FormData()
      for (let i in form) {
        data.append(i, form[i])
      }
      let response = await api.post("users_documents.json", data, undefined)
      return response
    }

    static async deleteFile(doc) {
      const response = await api.delete(`users_documents/${doc.id}.json`)
      return response
    }

    static async delete(data) {
      const response = await api.delete(`users/${data.id}.json?role=broker`)
      return response
    }


}