import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { StatisticAction } from "../../redux/actions";
import api from "../../api";
import { Form, Loader } from "semantic-ui-react";
import { AdminsDropdown } from "../../components";
import DatePicker from 'react-datepicker'
import moment from 'moment'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
};

const Charts = () => {
    const [params, setParams] = useState({
        page_size: 0,
        from_date: new Date(new Date().setDate(new Date().getDate() - 7)),
        from_date_str: moment(new Date(new Date().setDate(new Date().getDate() - 7))).format('DD.MM.yyyy')
    })
    const [clientsData, setClientsData] = useState({
        labels: [],
        datasets: []
    })
    const [clientsLoading, setClientsLoading] = useState(false)
    const [bookingsData, setBookingsData] = useState({
        labels: [],
        datasets: []
    })
    const [bookingsLoading, setBokingsLoading] = useState(false)

    const getClientsStatistic = async () => {
        setClientsLoading(true)
        const response = await api.get('statistic/clients.json', params);
        if (response.ok) {
            let data = await response.json()
            const dataset = {
                label: 'Клиенты',
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
            let labels = Object.keys(data)
            labels.forEach(label => {
                dataset.data.push(data[label])
            })
                
            
            setClientsData({...clientsData, labels: labels, datasets: [dataset]})
        }
        setClientsLoading(false)
    }
    const getBookingsStatistic = async () => {
        setBokingsLoading(true)
        const response = await api.get('statistic/bookings.json', params);
        if (response.ok) {
            let data = await response.json()
            const dataset = {
                label: 'Брони',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
            let labels = Object.keys(data)
            labels.forEach(label => {
                dataset.data.push(data[label])
            })
                
            
            setBookingsData({...bookingsData, labels: labels, datasets: [dataset]})
        }
        setBokingsLoading(false)
    }
    useEffect(() => {
        getClientsStatistic()
        getBookingsStatistic()
    }, [params])

    return (
        <>
            <Form>
                <Form.Field>
                    <label>Брокер</label>
                    <AdminsDropdown
                        role="all"
                        onChange={(broker) => setParams({...params, broker})}
                        value={params.broker}/>
                </Form.Field>
                <Form.Group widths={'equal'}>
                    <Form.Field>
                        <label>С какой даты</label>
                        <DatePicker 
                            isClearable
                            dateFormat={"dd.MM.yyyy"}
                            selected={params.from_date} 
                            onChange={(date) => setParams({...params, from_date: date, from_date_str: date && moment(date).format('DD.MM.yyyy')})} />
                    </Form.Field>
                    <Form.Field>
                        <label>По какую дату</label>
                        <DatePicker 
                            isClearable
                            dateFormat={"dd.MM.yyyy"}
                            selected={params.to_date} 
                            onChange={(date) => setParams({...params, to_date: date, to_date_str: date && moment(date).format('DD.MM.yyyy')})} />
                    </Form.Field>
                </Form.Group>
            </Form>
            {
                clientsLoading ?
                <Loader active/> :
                <Line options={options} data={clientsData} />
            }
            {
                bookingsLoading ?
                <Loader active/> :
                <Line options={options} data={bookingsData} />
            }
            
        </>
    )
}
export default Charts