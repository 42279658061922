export const ROLES_OPTIONS = [
  {
    key: "broker",
    text: "Брокер",
    value: "broker",
  },
  {
    key: "manager",
    text: "Менеджер",
    value: "manager",
  },
  {
    key: "admin",
    text: "Админ",
    value: "admin",
  },
  {
    key: "conflictolog",
    text: "Конфликтолог",
    value: "conflictolog",
  },
];
export const TRUE_FALSE_OPTIONS = [
  {
    key: "false",
    text: "Нет",
    value: "false",
  },
  {
    key: "true",
    text: "Да",
    value: "true",
  },
];

export const CLIENT_STATUS_OPTIONS = [
  {
    key: "Встреча в офисе",
    text: "Встреча в офисе",
    value: "Встреча в офисе",
  },
  {
    key: "Купил в другом месте",
    text: "Купил в другом месте",
    value: "Купил в другом месте",
  },
  {
    key: "Отказ",
    text: "Отказ",
    value: "Отказ",
  },
  {
    key: "Купил",
    text: "Купил",
    value: "Купил",
  },
  {
    key: "Клиент передан",
    text: "Клиент передан",
    value: "Клиент передан",
  },
  {
    key: "Первичный просмотр",
    text: "Первичный просмотр",
    value: "Первичный просмотр",
  },
  {
    key: "Договоренность о встрече",
    text: "Договоренность о встрече",
    value: "Договоренность о встрече",
  },
  {
    key: "Был показ",
    text: "Был показ",
    value: "Был показ",
  },
  {
    key: "Думает",
    text: "Думает",
    value: "Думает",
  },
];
export const DROPDOWN_OPTIONS = [
  {
    key: 10,
    value: 10,
    text: "10",
  },
  {
    key: 20,
    value: 20,
    text: "20",
  },
  {
    key: 50,
    value: 50,
    text: "50",
  },
  {
    key: 10000,
    value: 10000,
    text: "Все",
  },
];
