import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Grid, Menu, Segment } from "semantic-ui-react";
import StatisticTable from './table'
import StatisticChart from './charts'

const Statistic = () => {
    const { pathname  } = useLocation()
    return (
        <Grid>
            <Grid.Column width={3}>
                <Menu vertical >
                    <Menu.Item 
                        as={Link} 
                        to="/statistic/table"
                        active={pathname === '/statistic/table'}>Табличка</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to="/statistic/charts"
                        active={pathname === '/statistic/charts'}>Графики</Menu.Item>
                </Menu>
            </Grid.Column>
            <Grid.Column width={13}>
                <Segment>
                    <Routes>
                        <Route path="/table" element={<StatisticTable/>}/>
                        <Route path="/charts" element={<StatisticChart/>}/>
                    </Routes>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default Statistic