import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Grid, Header, Menu, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Brokers from "./brokers";
import Conflicts from "./conflicts";
import Requests from "./requests";
import { useSelector } from "react-redux";

const BrokersIndex = () => {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.auth);
  const isAdmin = user.role === "admin";

  return (
    <Grid>
      <Grid.Column width={3}>
        <Menu vertical>
          <Menu.Item as={Link} to="/brokers" active={pathname === "/brokers"}>
            Брокеры
          </Menu.Item>
          {isAdmin && (
            <Menu.Item
              as={Link}
              to="/brokers/conflicts"
              active={pathname === "/brokers/conflicts"}
            >
              Конфликты
            </Menu.Item>
          )}
          <Menu.Item
            as={Link}
            to="/brokers/requests"
            active={pathname === "/brokers/requests"}
          >
            Заявки
          </Menu.Item>
        </Menu>
      </Grid.Column>
      <Grid.Column width={13}>
        <Segment>
          <Routes>
            <Route path="/*" element={<Brokers />} />
            <Route path="/conflicts" element={<Conflicts />} />
            <Route path="/requests" element={<Requests />} />
          </Routes>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default BrokersIndex;
