import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Header,
  Pagination,
  Table,
} from "semantic-ui-react";
import { ConflictsAction } from "../../../redux/actions";
import moment from "moment";
import "moment/locale/ru"; // without this line it didn't work
import { AdminsDropdown } from "../../../components";
import ExcelDownload from "./excel";
import { DROPDOWN_OPTIONS } from "../../../constants";
import { Link } from "react-router-dom";
moment.locale("ru");

const Conflicts = () => {
  const [excelModal, setExcelModal] = useState(false);
  const [params, setParams] = useState({
    page_size: 10,
  });

  useEffect(() => {
    ConflictsAction.find(params);
  }, [params]);

  const conflicts = useSelector((state) => state.conflict).list;

  return (
    <>
      <Header dividing>
        <Button
          floated="right"
          content="Выгрузить"
          onClick={() => setExcelModal(true)}
          icon="file excel"
        />

        <Header.Content>Конфликты</Header.Content>
        <Header.Subheader>Всего конфликтов: {conflicts.count}</Header.Subheader>
      </Header>
      <Form>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Брокер</label>
            <AdminsDropdown
              role="all"
              onChange={(broker) => setParams({ ...params, broker, page: 1 })}
              value={params.broker}
            />
          </Form.Field>
          <Form.Field>
            <label>Вор</label>
            <AdminsDropdown
              role="all"
              onChange={(stealer) => setParams({ ...params, stealer, page: 1 })}
              value={params.stealer}
            />
          </Form.Field>
          <Form.Input
            label="Телефон клиента"
            placeholder="Введите телефон клиента"
            value={params.phone}
            onChange={(e) =>
              setParams({ ...params, phone: e.target.value, page: 1 })
            }
          />
        </Form.Group>
      </Form>
      {conflicts.results && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Брокер</Table.HeaderCell>
              <Table.HeaderCell>Вор</Table.HeaderCell>
              <Table.HeaderCell>Клиент</Table.HeaderCell>
              <Table.HeaderCell>Дата</Table.HeaderCell>
              <Table.HeaderCell>Попытка</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {conflicts.results.map((conflict) => (
              <Table.Row key={conflict.id}>
                <Table.Cell>{conflict.broker_name}</Table.Cell>
                <Table.Cell>{conflict.stealer_name}</Table.Cell>
                <Table.Cell>
                  <Link to={`/clients/info/${conflict.client}`}>
                    {conflict.client_phone}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {moment(conflict.date_created).format("lll")}
                </Table.Cell>
                <Table.Cell>{conflict.trying}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell className={"overflowVisible"}>
                <Dropdown
                  selection
                  onChange={(_e, { value }) =>
                    setParams({ ...params, page_size: value })
                  }
                  value={params.page_size}
                  options={DROPDOWN_OPTIONS}
                />
              </Table.Cell>
              <Table.Cell textAlign="right" colspan={4}>
                {params.page_size > 0 && (
                  <Pagination
                    onPageChange={(_page, { activePage }) =>
                      setParams({ ...params, page: activePage })
                    }
                    totalPages={Math.ceil(conflicts.count / params.page_size)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      {excelModal && <ExcelDownload onClose={() => setExcelModal(false)} />}
    </>
  );
};

export default Conflicts;
