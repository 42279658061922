import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Divider, Form, Header } from "semantic-ui-react";
import { FilesInput } from "../../../components";
import { ObjectAction } from "../../../redux/actions";

const DetailsInfo = ({object, onChange}) => {
    const [form, setForm] = useState(object)

    const submit = async () => {
        const resposne = await ObjectAction.update(form)
        if (resposne.ok){
            toast.success("Объект сохранен")
        }
    }

    const uploadFile = async (document) => {
        const response = await ObjectAction.uploadFile({...document, village: object.id})
        if (response.ok) {
            onChange()
        }   
    }

    const deleteFile = async (document) => {
        const response = await ObjectAction.deleteFile(document)
        if (response.ok) {
            onChange()
        }
    }
    return (
        <Form>
            <Form.Input
                label='id'
                disabled
                value={form.id}/>
            <Form.Input
                label='Название'
                disabled
                value={form.name}/>
            <Form.Input
                label='Дней на бронь'
                onChange={(e) => setForm({...form, booking_time: e.target.value})}
                value={form.booking_time}/>
            <Form.Input
                label='Максимум обновлений'
                onChange={(e) => setForm({...form, max_updates: e.target.value})}
                value={form.max_updates}/>
            <Header
                content="Документы поселка"
                icon="book"
                dividing/>
            <Form.Field>
                <label>Документы</label>
                <FilesInput
                    documents={object.documents}
                    onDelete={deleteFile}
                    onChange={uploadFile}/>
            </Form.Field>
            <Divider/>
            <Button
                content="Сохранить"
                icon='save'
                onClick={submit}/>
        </Form>
    )
}

export default DetailsInfo