import store from '../store'
import api from '../../api'
import { toast } from 'react-toastify'

export default class Actions {

    static async find(params){

      let response = await api.get("clients.json", params)
        if (response.ok) {
          store.dispatch({
            type: "CLIENTS_INDEX",
            payload: await response.json()
          })
        }
    }
    static async update(data){
      let response = await api.put(`clients/${data.id}.json`, data)
      if (response.ok){
        Actions.find()
        toast.success("Клиент обновлен")
      }
      return response
    }
    static async transfer(data){
      let response = await api.put(`clients/${data.id}/transfer.json`, data)
      if (response.ok){
        Actions.find()
        toast.success("Клиент передан")
      }
      return response
    }
    static async booking(data) {
      let response = await api.post(`clients/${data.id}/booking.json`, data)
      return response;
    }

    static async archive(data){
      let response = await api.put(`clients/${data.id}/archive.json`, data)
      if (response.ok){
        Actions.find({archived: false})
        toast.success("Клиент добавлен в архив")
      }
      return response
    }
    static async archive_many(data){
      let response = await api.post(`clients/archive_many.json`, data)
      return response
    }
    
    static async get(id){
      let response = await api.get(`clients/${id}.json`)
      return response
    }

    static async create(data){
      let response = await api.post(`clients.json`, data)
      if (response.ok){
        Actions.find({archived: false})
        toast.success("Клиент добавлен")
      }
      return response
    }
}