import store from "../store";
import api from "../../api";
import { toast } from "react-toastify";

export default class Actions {
  static async find(params) {
    let response = await api.get("showings.json", params);
    if (response.ok) {
      store.dispatch({
        type: "SHOWINGS_INDEX",
        payload: await response.json(),
      });
    }
  }
  static async create(data) {
    let response = await api.post(`showings.json`, data);
    if (response.ok) {
      Actions.find();
      toast.success("Показ добавлен");
    }
    return response;
  }
  static async get(id) {
    let response = await api.get(`showings/${id}.json`);
    return response;
  }

  static async update(data) {
    let response = await api.put(`showings/${data.id}.json`, data);
    if (response.ok) {
      Actions.find();
      toast.success("Показ обновлен");
    }
    return response;
  }
}
