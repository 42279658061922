import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Header, Modal } from "semantic-ui-react";
import { FilesInput } from "../../components";
import { AreaAction } from "../../redux/actions";


const Details = () => {
    const [bookModal, setBookModal] = useState(false)
    const [bookDays, setBookDays] = useState(0)

    const [form, setForm] = useState({
        documents: []
    })
    const {id} = useParams()

    const getArea = async () => {
        const response = await AreaAction.get(id)
        if (response.ok) {
            setForm(await response.json())
        }
    }

    useEffect(() => {
        getArea()
    }, [])

    const uploadFile = async (document) => {
        const response = await AreaAction.uploadFile({...document, area: form.id})
        if (response.ok) {
            getArea()
        }   
    }

    const deleteFile = async (document) => {
        const response = await AreaAction.deleteFile(document)
        if (response.ok) {
            getArea()
        }
    }
    const bookArea = async () => {
        const response = await AreaAction.book({id, days: bookDays})
        if (response.ok){
            toast.success("Участок забронирован")
            setBookModal(false)
            setBookDays(0)
        }
    }
    return (
        <>
            <Button
                floated="right"
                content="Забронировать"
                onClick={() => setBookModal(true)}/>
            <Header
                content={`Участок ${form.name}`}
                subheader={'Вы можете изменить документы участка'}/>
            <Form>
                <Form.Field>
                    <label>Документы</label>
                    <FilesInput
                        documents={form.documents}
                        onDelete={deleteFile}
                        onChange={uploadFile}/>
                </Form.Field>
            </Form>
            <Modal open={bookModal} onClose={() => setBookModal(false)}>
                <Modal.Header>
                    Забронировать участок
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            label="На сколько дней"
                            placeholder="Введите количество дней"
                            type="number"
                            value={bookDays}
                            onChange={(e) => setBookDays(e.target.value)}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Отменить"
                        onClick={() => setBookModal(false)}/>
                    <Button
                        content="Забронировать"
                        onClick={bookArea}/>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default Details