import { combineReducers } from "redux";
import AuthReducer from "./reducers/auth";
import BrokerReducer from "./reducers/brokers";
import ConflictsReducer from "./reducers/conflicts";
import AdminReducer from "./reducers/admin";
import ClientReducer from "./reducers/clients";
import ObjectReducer from "./reducers/objects";
import AreaReducer from "./reducers/areas";
import BookingReducer from "./reducers/bookings";
import StatisticReducer from "./reducers/statistic";
import UserReducer from "./reducers/user";
import ShowingReducer from "./reducers/showings";

export default () =>
  combineReducers({
    auth: AuthReducer,
    broker: BrokerReducer,
    conflict: ConflictsReducer,
    admin: AdminReducer,
    client: ClientReducer,
    object: ObjectReducer,
    booking: BookingReducer,
    area: AreaReducer,
    statistic: StatisticReducer,
    user: UserReducer,
    showing: ShowingReducer,
  });
