import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Modal, Popup } from "semantic-ui-react";
import api from "../../../api";
import { Link } from "react-router-dom";
import { AdminsDropdown, FilesInput, RolesDropdown } from "../../../components";
import { BrokerAction, UserAction } from "../../../redux/actions";
import { toast } from "react-toastify";


export default () => {
    const [form, setForm] = useState({
        documents: []
    })
    const {id} = useParams()

    const getBroker = async () => {
        const response = await api.get(`users/${id}.json?role=broker`)
        if (response.ok){
            const data = await response.json()
            setForm(data)
        }
    }

    useEffect(() => {
        if (id){
            getBroker()
        }
    }, [id])
    const navigate = useNavigate()
    const submit = async () => {
        const response = await BrokerAction.update(form)
        if (response.ok){
            navigate("/brokers")
        }
    }

    const uploadFile = async (document) => {
        const response = await BrokerAction.uploadFile({...document, user: form.id})
        if (response.ok) {
            getBroker()
        }   
    }

    const deleteFile = async (document) => {
        const response = await BrokerAction.deleteFile(document)
        if (response.ok) {
            getBroker()
        }
    }

    const deleteBroker = async () => {
        const response = await BrokerAction.delete({id})
        if (response.ok) {
            toast.success("Брокер удален")
            BrokerAction.find()
            navigate('/brokers')
        }
    }

    return (
        <Modal open>
            <Modal.Header>
                Редактировать брокера
            </Modal.Header>
            <Modal.Content>
                <Form onSubmit={submit}>
                    <Form.Input 
                        label="Почта"
                        disabled
                        value={form.username}/>
                    <Form.Input 
                        label="Имя"
                        placeholder="Введите имя"
                        onChange={(e) => setForm({...form, first_name: e.target.value})}
                        value={form.first_name}/>
                    <Form.Input 
                        label="Фамилия"
                        placeholder="Введите фамилию"
                        onChange={(e) => setForm({...form, last_name: e.target.value})}
                        value={form.last_name}/>
                    <Form.Field>
                        <label>Роль</label>
                        <RolesDropdown
                            onChange={(role) => setForm({...form, role})}
                            value={form.role}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Админ</label>
                        <AdminsDropdown
                            onChange={(admin) => setForm({...form, admin})}
                            value={form.admin}/>
                    </Form.Field>
                    <Form.Checkbox
                        checked={form.is_active}
                        onChange={(e, {checked}) => setForm({...form, is_active: checked})}
                        label="Активен"
                        />
                    <Form.Checkbox
                        checked={form.notifications}
                        onChange={(e, {checked}) => setForm({...form, notifications: checked})}
                        label="Получает оповещания"
                        />
                    <Form.Field>
                        <label>Документы</label>
                        <FilesInput
                            documents={form.documents}
                            onDelete={deleteFile}
                            onChange={uploadFile}/>
                    </Form.Field>
                    <Popup
                        content={
                            <Button.Group>
                                <Button
                                    content="Да"
                                    onClick={deleteBroker}/>
                            </Button.Group>
                        }
                        on='click'
                        pinned
                        trigger={<Button type="button" content='Удалить' color="red"/>}/>
                    <Button
                        as={Link}
                        to="/brokers"
                        content="Отменить"/>
                    <Button
                        type="submit"
                        color="green"
                        content="Сохранить"/>
                </Form>
            </Modal.Content>
        </Modal>
    )
}