import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import {utils, writeFile} from 'xlsx'
import api from "../../api";
import { StatusDropdown, AdminsDropdown } from "../../components";
import DatePicker from 'react-datepicker'
import moment from 'moment'

const Excel = ({onClose}) => {
    const [params, setParams] = useState({
        page_size: 0
    })
    const [loading, setLoading] = useState(false)

    const downlaod = async () => {
        setLoading(true)
        const response = await api.get("clients/export.json", params)
        if (response.ok) {
            let wb = utils.book_new()
            let ws = utils.json_to_sheet((await response.json()))

            utils.book_append_sheet(wb, ws, "Clients")
            writeFile(wb, "clients.xlsx")
        }
        setLoading(false)
    }
    return (
        <Modal open onClose={onClose}>
            <Modal.Header>
                Выгрузить клиентов
            </Modal.Header>
            <Modal.Content>
            <Form>
                <Form.Group widths={'equal'}>
                    <Form.Input
                        label="ФИО"
                        placeholder="Введите фио клиента"
                        onChange={(e) => setParams({...params, name: e.target.value})}
                        value={params.name}/>
                    <Form.Input
                        label="Телефон"
                        placeholder="Введите телефон"
                        onChange={(e) => setParams({...params, phone: e.target.value})}
                        value={params.phone}/>
                </Form.Group>
                <Form.Group widths={'equal'}>
                    <Form.Field>
                        <label>Брокер</label>
                        <AdminsDropdown
                            role="all"
                            onChange={(broker) => setParams({...params, broker})}
                            value={params.broker}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Статус</label>
                        <StatusDropdown
                            value={params.status}
                            onChange={(status) => setParams({...params, status})}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths={'equal'}>
                    <Form.Field>
                        <label>С какой даты</label>
                        <DatePicker 
                            isClearable
                            dateFormat={"dd.MM.yyyy"}
                            selected={params.from_date} 
                            onChange={(date) => setParams({...params, from_date: date, from_date_str: date && moment(date).format('DD.MM.yyyy')})} />
                    </Form.Field>
                    <Form.Field>
                        <label>По какую дату</label>
                        <DatePicker 
                            isClearable
                            dateFormat={"dd.MM.yyyy"}
                            selected={params.to_date} 
                            onChange={(date) => setParams({...params, to_date: date, to_date_str: date && moment(date).format('DD.MM.yyyy')})} />
                    </Form.Field>
                </Form.Group>
            </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Закрыть"
                    onClick={onClose}/>
                <Button
                    loading={loading}
                    disabled={loading}
                    color="green"
                    content="Выгрузить"
                    onClick={downlaod}/>
            </Modal.Actions>
        </Modal>
    )
}

export default Excel