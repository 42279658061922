import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Header, Icon, Menu } from "semantic-ui-react";
import { AuthAction, UserAction } from "../redux/actions";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import api from "../api";
export default () => {
  const user = useSelector((state) => state.user).data;

  const { pathname } = useLocation();

  const isAdmin = user.role === "admin";
  const isManager = user.role === "manager";
  const isBroker = user.role === "broker";
  const isConflictolog = user.role === "conflictolog";

  const refreshData = () => {
    api.post("areas/refresh.json");
  };

  useEffect(() => {
    UserAction.info();
  }, []);
  return (
    <>
      <Menu text>
        <Menu.Menu position="left">
          <Menu.Item as={Link} to="/brokers">
            <img alt="logo" className="logo" src="/img/logo.png" />
          </Menu.Item>
        </Menu.Menu>

        <Menu.Menu position="right">
          <Menu.Item>
            <Header
              as={Link}
              to={"/user/info"}
              content={user.first_name + " " + user.last_name}
              subheader={user.username}
            />
          </Menu.Item>
          <Menu.Item onClick={AuthAction.logout}>
            <Icon name={"log out"} size="big" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Menu color="green" inverted>
        <Menu.Menu>
          {(isAdmin || isManager) && (
            <Menu.Item
              as={Link}
              to="/brokers"
              content="Брокеры"
              active={pathname.includes("/brokers")}
              icon="user circle"
            />
          )}
          {isAdmin && (
            <Menu.Item
              as={Link}
              to="/admins"
              content="Админы"
              active={pathname.includes("/admins")}
              icon="user plus"
            />
          )}

          {!isConflictolog && (
            <>
              <Menu.Item
                as={Link}
                to="/clients"
                content="Клиенты"
                active={pathname.includes("/clients")}
                icon="users"
              />
              <Menu.Item
                as={Link}
                to="/bookings"
                content="Брони"
                active={pathname.includes("/bookings")}
                icon="lock"
              />
            </>
          )}
          {(isBroker || isConflictolog) && (
            <Menu.Item
              as={Link}
              to="/conflicts"
              content="Конфликты"
              active={pathname.includes("/conflicts")}
              icon="ban"
            />
          )}
          {(isAdmin || isConflictolog) && (
            <Menu.Item
              as={Link}
              to="/objects"
              content="Объекты"
              active={pathname.includes("/objects")}
              icon="warehouse"
            />
          )}
          {(isAdmin || isConflictolog) && (
            <Menu.Item
              as={Link}
              to="/statistic/table"
              content="Статистика"
              active={pathname.includes("/statistic")}
              icon="chart bar"
            />
          )}
        </Menu.Menu>
        {isAdmin && (
          <Menu.Menu position="right">
            <Menu.Item
              as={Button}
              onClick={refreshData}
              content="Обновить"
              icon={"refresh"}
            />
          </Menu.Menu>
        )}
      </Menu>
    </>
  );
};
