const initialState = {
    table: {}
  }
    
export default function(state = initialState, action) {
    switch(action.type){
    case "STATISTIC_TABLE":
        return { ...state,  table: action.payload }
    default: {
            return state
        }
    }
}
    