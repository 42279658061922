import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import api from "../api";

const ClientsDropdown = ({ value, onChange, role }) => {
  const [term, setTerm] = useState("");
  const [opened, setOpened] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getClients = async () => {
      const response = await api.get("clients.json", {
        page_size: 10,
        name: term,
      });
      if (response.ok) {
        const data = await response.json();
        setClients(data.results);
      }
    };
    getClients();
  }, [term]);

  const options = useMemo(() => {
    const data = opened
      ? clients
      : clients.filter((client) => client.id === value);
    let options = data.map((row) => ({
      key: row.id,
      text: row.phone,
      value: row.id,
      description: row.name,
    }));
    return options;
  }, [clients, term, opened]);

  return (
    <Dropdown
      fluid
      search
      selection
      clearable
      value={value}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      onSearchChange={(e, { searchQuery }) => setTerm(searchQuery)}
      onChange={(e, { value }) => onChange(value)}
      options={options}
      placeholder="Начните вводить"
      noResultsMessage="Ничего не найдено"
      selectOnBlur={false}
    />
  );
};

export default ClientsDropdown;
