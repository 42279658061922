import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import * as Views from "./views";
import { Nav } from "./components";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { UserAction } from "./redux/actions";

function App() {
  const authUser = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user).data;

  const isAuth = !!authUser.token;
  const isAdmin = user.role === "admin";
  const isManager = user.role === "manager";
  const isConflictolog = user.role === "conflictolog";

  useEffect(() => {
    if (isAuth) UserAction.info();
  }, [isAuth]);

  return (
    <Container>
      <ToastContainer />
      <Router>
        {isAuth && <Nav />}
        <Routes>
          {isAuth ? (
            <>
              {(isManager || isAdmin) && (
                <>
                  <Route path="/" element={<Views.BrokersIndex />} />
                  <Route path="/brokers/*" element={<Views.BrokersIndex />} />
                </>
              )}

              <>
                <Route path="/clients/*" element={<Views.ClientsIndex />} />
                <Route
                  path="/clients/info/:id"
                  element={<Views.ClientsInfo />}
                />
                <Route path="/objects" element={<Views.ObjectsIndex />} />
                <Route path="/bookings" element={<Views.BookingsIndex />} />
                <Route path="/user/info" element={<Views.UserInfo />} />
              </>
              <Route path="/conflicts" element={<Views.ConflictsIndex />} />
              {(isAdmin || isConflictolog) && (
                <>
                  <Route
                    path="/objects/:id"
                    element={<Views.ObjectsDetails />}
                  />
                  <Route path="/areas/:id" element={<Views.AreasDetails />} />
                  <Route
                    path="/statistic/*"
                    element={<Views.StatisticIndex />}
                  />
                </>
              )}
              {isAdmin && (
                <>
                  <Route path="/admins/*" element={<Views.AdminsIndex />} />
                </>
              )}
            </>
          ) : (
            <>
              <Route path="/auth/*" element={<Views.AuthIndex />} />
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </>
          )}
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
