import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Form, Header, Pagination, Table } from "semantic-ui-react";
import { DROPDOWN_OPTIONS } from "../../../constants";
import { AreaAction } from "../../../redux/actions";

const Areas = ({object}) => {
    const [params, setParams] = useState({
        village: object.id,
        page_size: 10
    })

    useEffect(() => {
        AreaAction.find(params)
    }, [params])
    const areas = useSelector(state => state.area).list
    return (
        <>
            <Header
                content={`Участки объекта`}
                subheader={`Всего участков ${areas.count}`}
                dividing/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        value={params.name}
                        onChange={(e) => setParams({...params, name: e.target.value})}
                        label={'Название'}
                        placeholder="Введите название"
                        />
                </Form.Group>
            </Form>
            {
                areas.results && 
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.Cell colspan={3}></Table.Cell>
                            <Table.Cell colspan={4}>Цена</Table.Cell>
                            <Table.Cell colspan={5}>Цена со скидкой</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Название</Table.HeaderCell>
                            <Table.HeaderCell>Статус</Table.HeaderCell>
                            <Table.HeaderCell>Земля</Table.HeaderCell>
                            <Table.HeaderCell>Обустройство</Table.HeaderCell>
                            <Table.HeaderCell>Сервис</Table.HeaderCell>
                            <Table.HeaderCell>Общая</Table.HeaderCell>
                            <Table.HeaderCell>Земля</Table.HeaderCell>
                            <Table.HeaderCell>Обустройство</Table.HeaderCell>
                            <Table.HeaderCell>Сервис</Table.HeaderCell>
                            <Table.HeaderCell>Общая</Table.HeaderCell>
                            <Table.HeaderCell>Конец</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            areas.results.map(area => 
                                <Table.Row key={area.id}>
                                    <Table.Cell>
                                        <Link to={`/areas/${area.id}`}>
                                            {area.name}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{area.status}</Table.Cell>
                                    <Table.Cell>{area.price_land}</Table.Cell>
                                    <Table.Cell>{area.arrangement_price}</Table.Cell>
                                    <Table.Cell>{area.service_price}</Table.Cell>
                                    <Table.Cell>{area.full_price}</Table.Cell>
                                    <Table.Cell>{area.land_price_discount}</Table.Cell>
                                    <Table.Cell>{area.arrangement_price_discount}</Table.Cell>
                                    <Table.Cell>{area.service_price_discount}</Table.Cell>
                                    <Table.Cell>{area.full_price_discount}</Table.Cell>
                                    <Table.Cell>{area.discount_end}</Table.Cell>
                                </Table.Row>
                                )
                        }
                    </Table.Body>
                    <Table.Footer>
                    <Table.Row>
                        <Table.Cell>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={10}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(areas.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
                </Table>
            }
        </>
    )

}
export default Areas