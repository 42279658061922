import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Form, Header, Pagination, Table } from "semantic-ui-react";
import { ObjectAction } from "../../redux/actions";
import { Link } from "react-router-dom";
import { DROPDOWN_OPTIONS } from "../../constants";

const Index = () => {
    const [params, setParams] = useState({
        page_size: 10
    })

    useEffect(() => {
        ObjectAction.find(params)
    }, [params])

    const objects = useSelector(state => state.object).list

    return (
        <>
            <Header
                content={"Объекты"}
                subheader={`Всего объектов ${objects.count}`}
                icon='warehouse'
                dividing/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        value={params.name}
                        onChange={(e) => setParams({...params, name: e.target.value, page: 1})}
                        label={'Название'}
                        placeholder="Введите название"
                        />
                </Form.Group>
            </Form>
            {
                objects.results && 
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Название</Table.HeaderCell>
                            <Table.HeaderCell>Дней на бронь</Table.HeaderCell>
                            <Table.HeaderCell>Максимум обновлений</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            objects.results.map(obj => 
                                <Table.Row key={obj.id}>
                                    <Table.Cell>
                                        <Link to={`/objects/${obj.id}`}>
                                            {obj.name}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{obj.booking_time}</Table.Cell>
                                    <Table.Cell>{obj.max_updates}</Table.Cell>
                                </Table.Row>
                                )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={2}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(objects.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
                </Table>
            }
        </>
    )
}

export default Index