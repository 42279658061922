import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { ClientAction } from "../../redux/actions";
import { StatusDropdown } from "../../components";

const ClientForm = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const submit = async () => {
    const response = await ClientAction.create(form);
    if (response.ok) {
      navigate("/clients");
    } else {
      setErrors(await response.json());
    }
  };

  return (
    <>
      <Modal open>
        <Modal.Header>{"Добавить клиента"}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              label="ФИО"
              placeholder="Введите фио клиента"
              value={form.name}
              error={errors.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
            <Form.Input
              label="Телефон"
              placeholder="Введите телефон"
              value={form.phone}
              error={errors.phone}
              onChange={(e) => setForm({ ...form, phone: e.target.value })}
            />
            <Form.Input
              label="Телефон 2"
              placeholder="Введите телефон 2"
              value={form.phone2}
              error={errors.phone2}
              onChange={(e) => setForm({ ...form, phone2: e.target.value })}
            />
            <Form.Input
              label="Телефон 3"
              placeholder="Введите телефон 3"
              value={form.phone3}
              error={errors.phone3}
              onChange={(e) => setForm({ ...form, phone3: e.target.value })}
            />
            <Form.Input
              label="Комментарий"
              placeholder="Введите комментарий"
              value={form.comment}
              onChange={(e) => setForm({ ...form, comment: e.target.value })}
            />
            <Form.Field>
              <label>Статус</label>
              <StatusDropdown
                value={form.status}
                onChange={(status) => setForm({ ...form, status })}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Отменить" as={Link} to="/clients" />
          <Button content="Сохранить" color="green" onClick={submit} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ClientForm;
