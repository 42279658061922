import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { AdminsDropdown, ClientsDropdown } from "../../../components";
import {utils, writeFile} from 'xlsx'
import api from "../../../api";
import DatePicker from 'react-datepicker'
import moment from 'moment'

const Excel = ({onClose}) => {
    const [params, setParams] = useState({
        page_size: 0
    })
    const [loading, setLoading] = useState(false)

    const downlaod = async () => {
        setLoading(true)
        const response = await api.get("conflicts/export.json", params)
        if (response.ok) {
            let wb = utils.book_new()
            let ws = utils.json_to_sheet((await response.json()))

            utils.book_append_sheet(wb, ws, "Conflicts")
            writeFile(wb, "conflicts.xlsx")
        }
        setLoading(false)
    }
    return (
        <Modal open onClose={onClose}>
            <Modal.Header>
                Выгрузить конфликты
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths={'equal'}>
                        <Form.Field>
                            <label>Брокер</label>
                            <AdminsDropdown
                                role="all"
                                onChange={(broker) => setParams({...params, broker})}
                                value={params.broker}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Вор</label>
                            <AdminsDropdown
                                role="all"
                                onChange={(stealer) => setParams({...params, stealer})}
                                value={params.stealer}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Клиент</label>
                            <ClientsDropdown
                                onChange={(client) => setParams({...params, client})}
                                value={params.client}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <Form.Field>
                            <label>С какой даты</label>
                            <DatePicker 
                                isClearable
                                dateFormat={"dd.MM.yyyy"}
                                selected={params.from_date} 
                                onChange={(date) => setParams({...params, from_date: date, from_date_str: date && moment(date).format('DD.MM.yyyy')})} />
                        </Form.Field>
                        <Form.Field>
                            <label>По какую дату</label>
                            <DatePicker 
                                isClearable
                                dateFormat={"dd.MM.yyyy"}
                                selected={params.to_date} 
                                onChange={(date) => setParams({...params, to_date: date, to_date_str: date && moment(date).format('DD.MM.yyyy')})} />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Закрыть"
                    onClick={onClose}/>
                <Button
                    loading={loading}
                    disabled={loading}
                    color="green"
                    content="Выгрузить"
                    onClick={downlaod}/>
            </Modal.Actions>
        </Modal>
    )
}

export default Excel