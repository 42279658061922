import api from '../../api'
import store from '../store'

export default class {
  static async register(data){
    const response = await api.post("users.json", data);
    return response
    
    
  }
  static async login(data){
    const response = await api.post('auth/', data);
    data = await response.json();
    localStorage.setItem("auth", JSON.stringify(data))
    store.dispatch({
      type: "LOGIN",
      payload: data
    })
    return response
  }

  static logout(){
    localStorage.removeItem("auth")
    store.dispatch({
      type: "LOGOUT"
    })
  }
}
