import React, { useEffect, useState } from "react";
import { Dropdown, Form, Header, Label, Pagination, Segment, Table } from "semantic-ui-react";
import { AdminAction } from "../../redux/actions";
import { Link, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import EditAdmin from './edit'
import { DROPDOWN_OPTIONS } from "../../constants";

const AdminsIndex = () => {
    const [params, setParams] = useState({
        page_size: 10
    })
    const admins = useSelector(state => state.admin).list

    useEffect(() => {
        AdminAction.find(params)
    }, [params])

    return (
        <Segment>
            <Header
                content="Администраторы"
                subheader="Супер админы и менеджеры"
                icon="user plus"/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        placeholder="Введите имя"
                        value={params.first_name}
                        label="Имя"
                        onChange={(e) => setParams({...params, first_name: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите фамилию"
                        value={params.last_name}
                        label="Фамилия"
                        onChange={(e) => setParams({...params, last_name: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите почту"
                        value={params.username}
                        label="Почта"
                        onChange={(e) => setParams({...params, username: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите телефон"
                        value={params.phone}
                        label="Телефон"
                        onChange={(e) => setParams({...params, phone: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите роль"
                        value={params.role}
                        label="роль"
                        onChange={(e) => setParams({...params, role: e.target.value, page: 1})}/>
                </Form.Group>
            </Form>
            <Routes>
                <Route path="/:id" element={<EditAdmin/>}/>
            </Routes>
            {
                admins.results &&
                <Table celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>Имя</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Почта</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Телефон</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Роль</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Действия</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            admins.results.map(admin => 
                                <Table.Row key={admin.id}>
                                    <Table.Cell>{`${admin.first_name} ${admin.last_name}`}</Table.Cell>
                                    <Table.Cell>{admin.username}</Table.Cell>
                                    <Table.Cell>{admin.phone}</Table.Cell>
                                    <Table.Cell>{admin.role === 'admin' ? "Супер админ" : "Менеджер"}</Table.Cell>
                                    <Table.Cell>
                                        <Label
                                            as={Link}
                                            to={`/admins/${admin.id}`}
                                            icon="edit"/>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell className={'overflowVisible'}>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={4}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(admins.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
                </Table>
            }
        </Segment>
    )
}

export default AdminsIndex
