import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Checkbox, Form, Header, Dropdown, Label, Modal, Table, Pagination } from "semantic-ui-react";
import { BrokerAction } from "../../../redux/actions";
import { Link, Route, Routes } from "react-router-dom";
import { DROPDOWN_OPTIONS, TRUE_FALSE_OPTIONS } from "../../../constants";
import EditBroker from './edit'
import { toast } from "react-toastify";

export default () => {
    const [telegramModal, setTelegramModal] = useState(false)
    const [tgLoading, setTgLoading] = useState(false)
    const [tgMessage, setTgMessage] = useState({
        files: []
    })
    const user = useSelector(state => state.auth)
    const isAdmin = user.role === 'admin'

    const [params, setParams] = useState({
        page_size: 10,
        role: 'broker',
        accepted: true
    })
    useEffect(() => {
        BrokerAction.find(params)
    }, [params])

    const sendTgMessage = async () => {
        setTgLoading(true)
        const response = await BrokerAction.tgMessage(tgMessage)
        if (response.ok){
            setTelegramModal(false)
            setTgMessage({})
            toast.success("Сообщение отправлено")
        }
        setTgLoading(false)
    }

    const brokers = useSelector(state => state.broker).list
    return (
        <>
            <Header dividing>
                {
                    isAdmin && 
                    <Button
                        floated="right"
                        content="Рассылка в ТГ"
                        onClick={() => setTelegramModal(true)}
                        icon="telegram"/>
                }
                
                <Header.Content>
                    Брокеры
                </Header.Content>
                <Header.Subheader>
                    Всего брокеров: {brokers.count}
                </Header.Subheader>
            </Header>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        placeholder="Введите имя"
                        value={params.first_name}
                        label="Имя"
                        onChange={(e) => setParams({...params, first_name: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите фамилию"
                        value={params.last_name}
                        label="Фамилия"
                        onChange={(e) => setParams({...params, last_name: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите почту"
                        value={params.username}
                        label="Почта"
                        onChange={(e) => setParams({...params, username: e.target.value, page: 1})}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input
                        placeholder="Введите телефон"
                        value={params.phone}
                        label="Телефон"
                        onChange={(e) => setParams({...params, phone: e.target.value, page: 1})}/>
                    <Form.Field>
                        <label>Активен</label>
                        <Dropdown 
                            placeholder='Выберите роль'
                            value={params.is_active}
                            clearable
                            selection
                            onChange={(e, { value }) => setParams({...params, is_active: value, page: 1})}
                            options={TRUE_FALSE_OPTIONS} />
                    </Form.Field>
                </Form.Group>
            </Form>
            {
                brokers.results &&
                <Table celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>Имя</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Почта</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Телефон</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Активен</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Действия</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            brokers.results.map(broker => 
                                <Table.Row key={broker.id}>
                                    <Table.Cell>{`${broker.first_name} ${broker.last_name}`}</Table.Cell>
                                    <Table.Cell>{broker.username}</Table.Cell>
                                    <Table.Cell>{broker.phone}</Table.Cell>
                                    <Table.Cell>{broker.is_active ? "да" : "нет"}</Table.Cell>
                                    <Table.Cell>
                                        <Label
                                            as={Link}
                                            to={`/brokers/${broker.id}`}
                                            icon="edit"/>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell className={'overflowVisible'}>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={4}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(brokers.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
                </Table>
            }
            <Routes>
                <Route path="/:id" element={<EditBroker/>}/>
            </Routes>
            <Modal open={telegramModal} onClose={() => setTelegramModal(false)}>
                <Modal.Header>
                    Рассылка в телеграм
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Сообщение</label>
                            <textarea
                                value={tgMessage.text}
                                onChange={(e) => setTgMessage({...tgMessage, text: e.target.value})}
                                placeholder={"Введите сообщение"}
                                rows={3}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Файл</label>
                            <input type={'file'} multiple placeholder='Выберите файл' onChange={(e) => setTgMessage({...tgMessage, files: e.target.files})}/>
                        </Form.Field>
                        <Form.Field>
                            <Button
                                content="Закрыть"
                                icon="close"
                                onClick={() => setTelegramModal(false)}/>
                            <Button
                                content="Отправить"
                                icon="send"
                                disabled={tgLoading}
                                loading={tgLoading}
                                onClick={sendTgMessage}/>
                        
                        </Form.Field>
                    </Form>
                </Modal.Content>
            </Modal>
        </>
    )
}
