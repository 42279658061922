import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ShowingsAction } from "../../../redux/actions";
import { ClientsDropdown, ObjectsDropdown } from "../../../components";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";

const ShowingForm = () => {
  const { id } = useParams();
  const isNew = id === "add";
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const user = useSelector((state) => state.user).data;

  const navigate = useNavigate();

  const submit = async () => {
    const response = isNew
      ? await ShowingsAction.create({ ...form, broker: user.id })
      : await ShowingsAction.update({ ...form, broker: user.id });
    if (response.ok) {
      if (isNew) navigate("/clients/showings");
    } else {
      setErrors(await response.json());
    }
  };

  useEffect(() => {
    if (!isNew) {
      ShowingsAction.get(id).then((response) => {
        response.json().then((data) => {
          setForm({ ...data, completed_date: new Date(data.completed_date) });
        });
      });
    }
  }, [id]);

  return (
    <>
      <Modal open>
        <Modal.Header>
          {isNew ? "Добавить показ" : "Обновить показ"}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={"equal"}>
              <Form.Field required error={errors.client}>
                <label>Клиент</label>
                <ClientsDropdown
                  onChange={(id) => setForm({ ...form, client: id })}
                  value={form.client}
                />
              </Form.Field>
              <Form.Field required error={errors.client}>
                <label>Поселок</label>
                <ObjectsDropdown
                  onChange={(id) => setForm({ ...form, village: id })}
                  value={form.village}
                />
              </Form.Field>
            </Form.Group>
            {!isNew && (
              <>
                <Form.Field>
                  <label>Дата показа</label>
                  <DatePicker
                    isClearable
                    dateFormat={"dd.MM.yyyy"}
                    selected={form.completed_date}
                    onChange={(date) =>
                      setForm({
                        ...form,
                        completed_date: date,
                      })
                    }
                  />
                </Form.Field>
                <Form.TextArea
                  label="Комментарий"
                  value={form.comment}
                  onChange={(e) =>
                    setForm({ ...form, comment: e.target.value })
                  }
                />
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Отменить" as={Link} to="/clients/showings" />
          <Button content="Сохранить" color="green" onClick={submit} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ShowingForm;
