import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Modal,
  Pagination,
  Table,
} from "semantic-ui-react";
import { ClientAction } from "../../redux/actions";
import { Link, Route, Routes } from "react-router-dom";
import ClientForm from "./form";
import { AdminsDropdown, StatusDropdown } from "../../components";
import moment from "moment";
import "moment/locale/ru"; // without this line it didn't work
import ExcelExport from "./excel";
import { toast } from "react-toastify";
import { DROPDOWN_OPTIONS } from "../../constants";
moment.locale("ru");
const Clients = () => {
  const user = useSelector((state) => state.auth);
  const [archiveModal, setArchiveModal] = useState(false);
  const [checkedClients, setChecketClients] = useState([]);
  const isAdmin = user.role === "admin";
  const [excelModal, setExcelModal] = useState(false);
  const [params, setParams] = useState({
    page_size: 10,
    archived: false,
    is_active: true,
  });
  useEffect(() => {
    ClientAction.find(params);
  }, [params]);

  const archiveClients = async () => {
    const response = await ClientAction.archive_many({
      clients: checkedClients,
    });
    if (response.ok) {
      ClientAction.find(params);
      toast.success("Клиенты архивированы");
      setArchiveModal(false);
    }
  };

  const clients = useSelector((state) => state.client).list;
  return (
    <>
      <Header dividing>
        <Button
          as={Link}
          to="/clients/add"
          floated="right"
          content="Добавить"
          icon="add"
        />
        {isAdmin && (
          <Button
            floated="right"
            content="Выгрузить"
            onClick={() => setExcelModal(true)}
            icon="file excel"
          />
        )}
        <Icon name="users" />
        <Header.Content>
          Клиенты
          <Header.Subheader>
            Количество клиентов {clients.count}
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Form>
        <Form.Group widths={"equal"}>
          <Form.Input
            label="ФИО"
            placeholder="Введите фио клиента"
            onChange={(e) =>
              setParams({ ...params, name: e.target.value, page: 1 })
            }
            value={params.name}
          />
          <Form.Input
            label="Телефон"
            placeholder="Введите телефон"
            onChange={(e) =>
              setParams({ ...params, phone: e.target.value, page: 1 })
            }
            value={params.phone}
          />
        </Form.Group>
        <Form.Group widths={"equal"}>
          {isAdmin && (
            <Form.Field>
              <label>Брокер</label>
              <AdminsDropdown
                role="all"
                onChange={(broker) => setParams({ ...params, broker, page: 1 })}
                value={params.broker}
              />
            </Form.Field>
          )}

          <Form.Field>
            <label>Статус</label>
            <StatusDropdown
              value={params.status}
              onChange={(status) => setParams({ ...params, status, page: 1 })}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <div>
        <Button
          content={"Архивировать"}
          onClick={() => setArchiveModal(true)}
          floated="right"
        />
        Выбрано {checkedClients.length} клиентов
      </div>

      {clients.results && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>ФИО</Table.HeaderCell>
              <Table.HeaderCell>Телефон</Table.HeaderCell>
              <Table.HeaderCell>Брокер</Table.HeaderCell>
              <Table.HeaderCell>К-во показов</Table.HeaderCell>
              <Table.HeaderCell>Статус</Table.HeaderCell>
              <Table.HeaderCell>Дата добавления</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clients.results.map((client) => (
              <Table.Row key={client.id}>
                <Table.Cell>
                  <Checkbox
                    checked={checkedClients.includes(client.id)}
                    onChange={(e, { checked }) =>
                      checked
                        ? setChecketClients([...checkedClients, client.id])
                        : setChecketClients(
                            checkedClients.filter((id) => id !== client.id)
                          )
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/clients/info/${client.id}`}>
                    {" "}
                    {client.name}({client.steals_count} попыток)
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/clients/info/${client.id}`}>{client.phone}</Link>
                </Table.Cell>
                <Table.Cell>{client.broker}</Table.Cell>
                <Table.Cell>{client.showings_count}</Table.Cell>
                <Table.Cell>{client.status}</Table.Cell>
                <Table.Cell>
                  {moment(client.date_created).format("LLL")}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colspan={2} className={"overflowVisible"}>
                <Dropdown
                  selection
                  onChange={(e, { value }) =>
                    setParams({ ...params, page_size: value })
                  }
                  value={params.page_size}
                  options={DROPDOWN_OPTIONS}
                />
              </Table.Cell>
              <Table.Cell textAlign="right" colspan={4}>
                {params.page_size > 0 && (
                  <Pagination
                    onPageChange={(page, { activePage }) =>
                      setParams({ ...params, page: activePage })
                    }
                    totalPages={Math.ceil(clients.count / params.page_size)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      {excelModal && isAdmin && (
        <ExcelExport onClose={() => setExcelModal(false)} />
      )}
      <Modal open={archiveModal} onClose={() => setArchiveModal(false)}>
        <Modal.Header>
          Архивировать {checkedClients.length} клиентов
        </Modal.Header>
        <Modal.Actions>
          <Button onClick={() => setArchiveModal(false)} content="Закрыть" />
          <Button
            color="green"
            onClick={archiveClients}
            content="Архивировать"
          />
        </Modal.Actions>
      </Modal>
      <Routes>
        <Route path="/add" element={<ClientForm />} />
      </Routes>
    </>
  );
};

export default Clients;
