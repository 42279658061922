import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Grid, Menu, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Clients from "./clients";
import ClientsArchive from "./archive";
import ShowingsIndex from "./showings/list";

const ClientsIndex = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <Menu>
        <Menu.Item as={Link} to="/clients" active={pathname === "/clients"}>
          Активные
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/clients/archive"
          active={pathname === "/clients/archive"}
        >
          Архив
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/clients/showings"
          active={pathname === "/clients/showings"}
        >
          Показы
        </Menu.Item>
      </Menu>
      <Segment>
        <Routes>
          <Route path="/*" element={<Clients />} />
          <Route path="/archive" element={<ClientsArchive />} />
          <Route path="/showings/*" element={<ShowingsIndex />} />
        </Routes>
      </Segment>
    </div>
  );
};

export default ClientsIndex;
