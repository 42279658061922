import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../api";
import { Link } from "react-router-dom";
import { RolesDropdown } from "../../components";
import { AdminAction} from "../../redux/actions";


export default () => {
    const [form, setForm] = useState({
        documents: []
    })
    const {id} = useParams()

    const getBroker = async () => {
        const response = await api.get(`users/${id}.json?role=admin`)
        if (response.ok){
            const data = await response.json()
            setForm(data)
        }
    }

    useEffect(() => {
        if (id){
            getBroker()
        }
    }, [id])
    const navigate = useNavigate()
    const submit = async () => {
        const response = await AdminAction.update(form)
        if (response.ok){
            navigate("/admins")
        }
    }


    return (
        <Modal open>
            <Modal.Header>
                Редактировать брокера
            </Modal.Header>
            <Modal.Content>
                <Form onSubmit={submit}>
                    <Form.Input 
                        label="Почта"
                        disabled
                        value={form.username}/>
                    <Form.Input 
                        label="Имя"
                        placeholder="Введите имя"
                        onChange={(e) => setForm({...form, first_name: e.target.value})}
                        value={form.first_name}/>
                    <Form.Input 
                        label="Фамилия"
                        placeholder="Введите фамилию"
                        onChange={(e) => setForm({...form, last_name: e.target.value})}
                        value={form.last_name}/>
                    <Form.Field>
                        <label>Роль</label>
                        <RolesDropdown
                            onChange={(role) => setForm({...form, role})}
                            value={form.role}/>
                    </Form.Field>
                    <Form.Checkbox
                        checked={form.is_active}
                        onChange={(e, {checked}) => setForm({...form, is_active: checked})}
                        label="Активен"
                        />
                    <Form.Checkbox
                        checked={form.notifications}
                        onChange={(e, {checked}) => setForm({...form, notifications: checked})}
                        label="Получает оповещания"
                        />
                    {/* <Form.Field>
                        <label>Документы</label>
                        <FilesInput
                            documents={form.documents}
                            onDelete={deleteFile}
                            onChange={uploadFile}/>
                    </Form.Field> */}
                    <Button
                        as={Link}
                        to="/admins"
                        content="Отменить"/>
                    <Button
                        type="submit"
                        color="green"
                        content="Сохранить"/>
                </Form>
            </Modal.Content>
        </Modal>
    )
}