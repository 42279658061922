import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Menu } from "semantic-ui-react";
import { ClientAction } from "../../redux/actions";
import ClientInfo from "./info/info";
import Bookings from "./info/bookings";
import Comments from "./info/comments";
import Actions from "./info/actions";
import { useSelector } from "react-redux";
const Info = () => {
  const [form, setForm] = useState({});
  const [activePage, setActivePage] = useState("info");

  const user = useSelector((state) => state.user).data;

  const { id } = useParams();

  const getClient = async () => {
    const response = await ClientAction.get(id);
    if (response.ok) {
      setForm(await response.json());
    }
  };

  useEffect(() => {
    getClient();
  }, [id]);

  return (
    <>
      <Header content={`Клиент ${form.name}`} icon="user" />
      <Menu widths={user.role === "admin" ? 4 : 3}>
        <Menu.Item
          content="Информация о клиенте"
          onClick={() => setActivePage("info")}
          active={activePage === "info"}
        />
        <Menu.Item
          content="Брони"
          onClick={() => setActivePage("bookings")}
          active={activePage === "bookings"}
        />
        <Menu.Item
          content="Комментарии"
          onClick={() => setActivePage("comments")}
          active={activePage === "comments"}
        />
        {user.role === "admin" && (
          <Menu.Item
            content="Действия"
            onClick={() => setActivePage("actions")}
            active={activePage === "actions"}
          />
        )}
      </Menu>
      {activePage === "info" && <ClientInfo />}
      {activePage === "bookings" && <Bookings />}
      {activePage === "comments" && <Comments />}
      {activePage === "actions" && <Actions />}
    </>
  );
};

export default Info;
