import store from '../store'
import api from '../../api'

export default class Actions {

    static async table(params){
        let response = await api.get("statistic/table.json", params)
        if (response.ok) {
          store.dispatch({
            type: "STATISTIC_TABLE",
            payload: await response.json()
          })
        }
    }
}