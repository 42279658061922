import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Form, Header, Modal, Table } from "semantic-ui-react";
import { BookingAction, ClientAction } from "../../../redux/actions";
import moment from 'moment'
import 'moment/locale/ru'  // without this line it didn't work
import { toast } from 'react-toastify'
import { AreasDropdown, ObjectsDropdown } from "../../../components";
moment.locale('ru')


const Bookings = () => {
    const user = useSelector(state => state.auth)
    const isAdmin = user.role === 'admin'

    const [deleteModal, setDeleteModal] = useState()
    const [renewalModal, setRenewalModal] = useState()
    const [bookingForm, setBookingForm] = useState({})
    const [bookingErrors, setBookingErrors] = useState({})
    const [bookingModal, setBookingModal] = useState(false)
    const {id} = useParams()
    const [params, setParams] = useState({
        client: id,
        page_size: 10
    })

    useEffect(() => {
        BookingAction.find(params)
    }, [params])

    const bookings = useSelector(state => state.booking).list

    const deleteBooking = async () => {
        const response = await BookingAction.delete(deleteModal)
        if (response.ok) {
            toast.success("Бронь удалена")
            setDeleteModal()
        }
    }
    const renewBooking = async () => {
        const response = await BookingAction.renew(renewalModal)
        if (response.ok) {
            toast.success("Бронь продлена")
            BookingAction.find(params)
            setRenewalModal()
        }
    }

    const createBooking = async () => {
        const response = await ClientAction.booking({...bookingForm, id})
        if (response.ok) {
            BookingAction.find(params)
            toast.success("Бронь добавлена")
            setBookingModal(false)
        }else{
            setBookingErrors(await response.json())
        }
    }
    return (
        <>
            
            <Header dividing>
                <Button
                    floated="right"
                    content="Добавить бронь"
                    onClick={() => setBookingModal(true)}
                    icon="add"/>
                <Header.Content>
                    Брони клиента
                </Header.Content>
                <Header.Subheader>
                    Всего броней {bookings.count}
                </Header.Subheader>
            </Header>
            {
                bookings.results &&
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Поселок</Table.HeaderCell>
                            <Table.HeaderCell>Участок</Table.HeaderCell>
                            <Table.HeaderCell>Продлений</Table.HeaderCell>
                            <Table.HeaderCell>Окончание</Table.HeaderCell>
                            <Table.HeaderCell>Действия</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            bookings.results.map(booking => 
                                <Table.Row key={booking.id}>
                                    <Table.Cell>{booking.object}</Table.Cell>
                                    <Table.Cell>{booking.area}</Table.Cell>
                                    <Table.Cell>{booking.renewals}</Table.Cell>
                                    <Table.Cell>
                                        {moment(booking.date_end).format('lll')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button
                                            onClick={() => setRenewalModal(booking.id)}
                                            content="Продлить"/>
                                        <Button
                                            onClick={() => setDeleteModal(booking.id)}
                                            color='red'
                                            content="Удалить"/>
                                    </Table.Cell>
                                </Table.Row>
                                )
                        }
                    </Table.Body>
                </Table>
            }
            <Modal open={!!deleteModal} size='small'>
                <Modal.Header>
                    Удалить бронь?
                </Modal.Header>
                <Modal.Actions>
                    <Button
                        content="Отменить"
                        onClick={() => setDeleteModal()}/>
                    <Button
                        content="Удалить"
                        color="red"
                        onClick={deleteBooking}/>
                </Modal.Actions>
            </Modal>
            <Modal open={!!renewalModal} size='small'>
                <Modal.Header>
                    Продлить бронь?
                </Modal.Header>
                <Modal.Actions>
                    <Button
                        content="Отменить"
                        onClick={() => setRenewalModal()}/>
                    <Button
                        content="Продлить"
                        color="green"
                        onClick={renewBooking}/>
                </Modal.Actions>
            </Modal>
            <Modal open={bookingModal} onClose={() => setBookingModal(false)}>
                <Modal.Header>
                    Добавить бронь
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Объект</label>
                            <ObjectsDropdown
                                error={bookingErrors.object}
                                onChange={(object) => setBookingForm({...bookingForm, object})}
                                value={bookingForm.object}/>
                        </Form.Field>
                        {
                            bookingForm.object &&
                            <Form.Field>
                                <label>Участок</label>
                                <AreasDropdown
                                    status={'Свободен'}
                                    error={bookingErrors.area}
                                    object={bookingForm.object}
                                    value={bookingForm.area}
                                    onChange={(area) => setBookingForm({...bookingForm, area})}/>
                            </Form.Field>
                        }
                        {
                            bookingForm.area && isAdmin && 
                            <Form.Input
                                label="На сколько бронь"
                                onChange={(e) => setBookingForm({...bookingForm, booking_time: e.target.value})}
                                value={bookingForm.booking_time}
                                placeholder={'На сколько дней бронь'}/>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => setBookingModal(false)}
                        content="Закрыть"/>
                    <Button
                        onClick={createBooking}
                        content="Забронировать"/>
                </Modal.Actions>
            </Modal>
            
        </>
    )

}

export default Bookings