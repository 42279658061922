const initialState = {
    data: {}
  }
    
export default function(state = initialState, action) {
    switch(action.type){
    case "USER_INFO":
        return { ...state,  data: action.payload }
    default: {
            return state
        }
    }
}
    