import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown, Header, Pagination, Table } from "semantic-ui-react";
import api from "../../../api";
import moment from "moment";
import "moment/locale/ru";
import { DROPDOWN_OPTIONS } from "../../../constants";

const Actions = () => {
  const { id } = useParams();
  const [params, setParams] = useState({
    page_size: 10,
    client: id,
  });
  const [actions, setActions] = useState({
    results: [],
  });
  const user = useSelector((state) => state.auth);

  const getActions = async () => {
    const response = await api.get("client_actions.json", params);
    if (response.ok) {
      setActions(await response.json());
    }
  };

  useEffect(() => {
    getActions();
  }, [params]);

  return (
    <>
      <Header
        content="Действия клиента"
        subheader="Действия с клиентом"
        dividing
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Действие</Table.HeaderCell>
            <Table.HeaderCell>Брокер</Table.HeaderCell>
            <Table.HeaderCell>Владелец клиента</Table.HeaderCell>
            <Table.HeaderCell>Дата</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {actions.results.map((obj) => (
            <Table.Row key={obj.id}>
              <Table.Cell>{obj.action}</Table.Cell>
              <Table.Cell>{obj.broker_name}</Table.Cell>
              <Table.Cell>{obj.owner_name}</Table.Cell>
              <Table.Cell>{moment(obj.date).format("lll")}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell>
              <Dropdown
                selection
                onChange={(e, { value }) =>
                  setParams({ ...params, page_size: value })
                }
                value={params.page_size}
                options={DROPDOWN_OPTIONS}
              />
            </Table.Cell>
            <Table.Cell textAlign="right" colspan={3}>
              {params.page_size > 0 && (
                <Pagination
                  onPageChange={(page, { activePage }) =>
                    setParams({ ...params, page: activePage })
                  }
                  totalPages={Math.ceil(actions.count / params.page_size)}
                />
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};
export default Actions;
