import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Form, Header, Pagination, Table } from "semantic-ui-react";
import { AdminsDropdown, StatusDropdown } from "../../components";
import { ClientAction } from "../../redux/actions";
import { Link } from "react-router-dom";
import { DROPDOWN_OPTIONS } from "../../constants";
import moment from 'moment'
import 'moment/locale/ru' 
moment.locale('ru')

const ClientsArchive = () => {
    const [params, setParams] = useState({
        page_size: 10,
        archived: true
    })

    useEffect(() => {
        ClientAction.find(params)
    }, [params])

    const clients = useSelector(state => state.client).list


    return (
        <>
            <Header
                icon="archive"
                content="Архив клиентов"
                subheader={`Клиентов в архиве ${clients.count}`}/>
            <Form>
                <Form.Group widths={'equal'}>
                    <Form.Input
                        label="ФИО"
                        placeholder="Введите фио клиента"
                        onChange={(e) => setParams({...params, name: e.target.value})}
                        value={params.name}/>
                    <Form.Input
                        label="Телефон"
                        placeholder="Введите телефон"
                        onChange={(e) => setParams({...params, phone: e.target.value})}
                        value={params.phone}/>
                </Form.Group>
                <Form.Group widths={'equal'}>
                    <Form.Field>
                        <label>Брокер</label>
                        <AdminsDropdown
                            role="all"
                            onChange={(broker) => setParams({...params, broker})}
                            value={params.broker}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Статус</label>
                        <StatusDropdown
                            value={params.status}
                            onChange={(status) => setParams({...params, status})}/>
                    </Form.Field>
                </Form.Group>
            </Form>
            {
                clients.results &&
                <Table celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>ФИО</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Телефон</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Брокер</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Статус</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Дата добавления</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            clients.results.map(client => 
                                <Table.Row key={client.id}>
                                    <Table.Cell>
                                        <Link to={`/clients/info/${client.id}`}>
                                            {client.name}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{client.phone}</Table.Cell>
                                    <Table.Cell>{client.broker}</Table.Cell>
                                    <Table.Cell>{client.status}</Table.Cell>
                                    <Table.Cell>
                                        {moment(client.date_created).format("LLL")}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell className={'overflowVisible'}>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={4}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(clients.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
                </Table>
            }
        </>
    )
}

export default ClientsArchive