import React, { useRef, useState } from "react";
import { Button, Form, Icon, Label, Modal } from "semantic-ui-react";

const wordFormats = [".doc", ".docx", ".txt"];
const imageFormats = [".png", ".jpeg", ".jpg"];
const pdfFormats = [".pdf"];

const FileInput = ({ documents, onChange, onDelete }) => {
  const [form, setForm] = useState({});
  const [addModal, setAddModal] = useState(false);
  const refFiles = useRef();

  const submit = () => {
    console.log(refFiles.current);
    refFiles.current.value = "";
    onChange(form);
    setAddModal(false);
    setForm({});
  };
  const getDocIcon = (doc) => {
    let url = doc.document || doc.url || "";
    if (url.includes(".doc") || url.includes(".docx")) return "file word";
    if (url.includes(".png") || url.includes(".jpeg") || url.includes(".jpg"))
      return "file image";
    return "file pdf";
  };
  const getDocColor = (doc) => {
    let url = doc.document || doc.url || "";
    if (url.includes(".doc") || url.includes(".docx")) return "blue";
    if (url.includes(".png") || url.includes(".jpeg") || url.includes(".jpg"))
      return "red";
    return "orange";
  };
  const getDocLink = (doc) => {
    return doc.document
      ? "https://apizemcrm.skillslab.center" + doc.document
      : doc.url;
  };
  return (
    <div>
      <div className="documents">
        {documents.map((doc) => (
          <div className="document">
            <Label
              floating
              onClick={() => onDelete(doc)}
              icon="delete"
              size="mini"
              color="red"
            />
            <Icon size="huge" name={getDocIcon(doc)} color={getDocColor(doc)} />
            <div>
              <a target="_blank" href={getDocLink(doc)}>
                {doc.name}
              </a>
            </div>
          </div>
        ))}
      </div>
      <Button
        content="Добавить"
        type="button"
        icon="add"
        onClick={() => setAddModal(true)}
      />

      <Modal open={addModal} onClose={() => setAddModal(false)}>
        <Modal.Header>Добавить документ</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Файл документа</label>
              <input
                ref={refFiles}
                type="file"
                onChange={(e) =>
                  setForm({ ...form, document: e.target.files[0] })
                }
              />
            </Form.Field>

            <Form.Input
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              value={form.name}
              label={"Имя документа"}
            />
            <Button onClick={submit} content={"Сохранить"} />
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default FileInput;
