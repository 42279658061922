import store from '../store'
import api from '../../api'

export default class Actions {

    static async info(params){
        let response = await api.get("users/info.json", params)
        if (response.ok) {
          const data = await response.json()
          store.dispatch({
            type: "USER_INFO",
            payload: data
          })
          return data
        }
    }
    
    static async updateInfo(user){
        let response = await api.put(`users/${user.id}.json`, user)
        if (response.ok){
          Actions.info()
        }
        return response

    }
}