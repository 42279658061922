import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Form, Header, Pagination, Table } from "semantic-ui-react";
import { BookingAction } from "../../redux/actions";
import moment from 'moment'
import 'moment/locale/ru'  // without this line it didn't work
import { AreasDropdown, ClientsDropdown, ObjectsDropdown } from "../../components";
import { DROPDOWN_OPTIONS } from "../../constants";
moment.locale('ru')

const Index = () => {
    const [params, setParams] = useState({
        page_size: 10
    })


    useEffect(() => {
        BookingAction.find(params)
    }, [params])

    const bookings = useSelector(state => state.booking).list
    return (
        <>
            <Header
                content="Брони"
                subheader={`Всего броней: ${bookings.count}`}
                icon={'lock'}/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <Form.Input
                            label="Номер клиента"
                            type="text"
                            placeholder="Введите номер клиента"
                            value={params.phone}
                            onChange={(e) => setParams({...params, phone: e.target.value, page: 1})}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Объект</label>
                        <ObjectsDropdown
                            onChange={(object) => setParams({...params, object, page: 1})}
                            value={params.object}/>
                    </Form.Field>
                    <Form.Input
                        label="Номер участка"
                        type="number"
                        placeholder="Введите номер участка"
                        value={params.area}
                        onChange={(e) => setParams({...params, area: e.target.value, page: 1})}/>
                </Form.Group>
            </Form>
            {
                bookings.results &&
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Клиент</Table.HeaderCell>
                            <Table.HeaderCell>Посёлок</Table.HeaderCell>
                            <Table.HeaderCell>Участок</Table.HeaderCell>
                            <Table.HeaderCell>Брокер</Table.HeaderCell>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Окончание</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            bookings.results.map(booking => 
                                <Table.Row key={booking.id}>
                                    <Table.Cell>
                                        {
                                            booking.client ?
                                            <Link to={`/clients/info/${booking.client.id}`}>
                                                {booking.client.name}
                                            </Link>
                                            :
                                            'Резерв'
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{booking.object}</Table.Cell>
                                    <Table.Cell>{booking.area}</Table.Cell>
                                    <Table.Cell>{booking.broker_name}</Table.Cell>
                                    <Table.Cell>
                                        {moment(booking.date_created).format('lll')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(booking.date_end).format('lll')}
                                    </Table.Cell>
                                </Table.Row>
                                )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell className={'overflowVisible'}>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={5}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(bookings.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
                </Table>
            }
            
        </>
    )
}

export default Index