import store from '../store'
import api from '../../api'
import { toast } from 'react-toastify'

export default class Actions {

    static async find(params){
        let response = await api.get("objects.json", params)
        if (response.ok) {
          store.dispatch({
            type: "OBJECTS_INDEX",
            payload: await response.json()
          })
        }
    }

    static async uploadFile(form) {
      const data = new FormData()
      for (let i in form) {
        data.append(i, form[i])
      }
      let response = await api.post("objects_documents.json", data, undefined)
      return response
    }

    static async deleteFile(doc) {
      const response = await api.delete(`objects_documents/${doc.id}.json`)
      return response
    }

    static async update(data){
      let response = await api.put(`objects/${data.id}.json`, data)
      return response
    }

    static async get(id){
      let response = await api.get(`objects/${id}.json`)
      return response
    }
}