import store from '../store'
import api from '../../api'

export default class Actions {

    static async find(params){

        let response = await api.get("conflicts.json", params)
        if (response.ok) {
          store.dispatch({
            type: "CONFLICTS_INDEX",
            payload: await response.json()
          })
        }
    }


}