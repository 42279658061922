import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Dropdown, Form, Header, Icon, Modal, Pagination, Table } from "semantic-ui-react";
import { DROPDOWN_OPTIONS } from "../../../constants";
import { BrokerAction } from "../../../redux/actions";


export default () => {
    const [acceptModal, setAcceptModal] = useState()
    const [deleteModal, setDeleteModal] = useState()

    const [params, setParams] = useState({
        page_size: 10,
        accepted: false,
        role: 'broker'
    })
    useEffect(() => {
        BrokerAction.find(params)
    }, [params])

    const acceptBroker = async (accept) => {
        const response = await BrokerAction.acceptBroker({accept, id: acceptModal || deleteModal})
        if (response.ok){
            toast.success(accept ? 'Брокер добавлен' : "Заявка удалена")
            setAcceptModal()
            setDeleteModal()
        }
    }
    const brokers = useSelector(state => state.broker).list
    return (
        <>
            <Header
                content="Брокеры"
                subheader={`Всего брокеров: ${brokers.count}`}/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        placeholder="Введите имя"
                        value={params.first_name}
                        label="Имя"
                        onChange={(e) => setParams({...params, first_name: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите фамилию"
                        value={params.last_name}
                        label="Фамилия"
                        onChange={(e) => setParams({...params, last_name: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите почту"
                        value={params.username}
                        label="Почта"
                        onChange={(e) => setParams({...params, username: e.target.value, page: 1})}/>
                    <Form.Input
                        placeholder="Введите телефон"
                        value={params.phone}
                        label="Телефон"
                        onChange={(e) => setParams({...params, phone: e.target.value, page: 1})}/>
                </Form.Group>
            </Form>
            {
                brokers.results &&
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Имя</Table.HeaderCell>
                            <Table.HeaderCell>Почта</Table.HeaderCell>
                            <Table.HeaderCell>Телефон</Table.HeaderCell>
                            <Table.HeaderCell>Действия</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            brokers.results.map(broker => 
                                <Table.Row key={broker.id}>
                                    <Table.Cell>{`${broker.first_name} ${broker.last_name}`}</Table.Cell>
                                    <Table.Cell>{broker.username}</Table.Cell>
                                    <Table.Cell>{broker.phone}</Table.Cell>
                                    <Table.Cell>
                                        <Button.Group>
                                            <Button
                                                color="green"
                                                content="Принять"
                                                onClick={() => setAcceptModal(broker.id)}/>
                                            <Button
                                                color="red"
                                                content="Удалить"
                                                onClick={() => setDeleteModal(broker.id)}/>
                                        </Button.Group>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell className={'overflowVisible'}>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={3}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(brokers.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
                </Table>
            }
            <Modal open={acceptModal !== undefined} closeIcon onClose={() => setAcceptModal()}>
                <Modal.Header>
                    Принять заявку?
                </Modal.Header>
                <Modal.Actions>
                    <Button
                        content="Отменить"
                        onClick={() => setAcceptModal(undefined)}/>
                    <Button
                        onClick={() => acceptBroker(true)}
                        content="Принять"/>
                </Modal.Actions>
            </Modal>
            <Modal open={deleteModal !== undefined} closeIcon onClose={() => setDeleteModal()}>
                <Modal.Header>
                    Удалить брокера?
                </Modal.Header>
                <Modal.Actions>
                    <Button
                        content="Отменить"
                        onClick={() => setDeleteModal(undefined)}/>
                    <Button
                        onClick={() => acceptBroker(false)}
                        content="Удалить"/>
                </Modal.Actions>
            </Modal>
            
        </>
    )
}