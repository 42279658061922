const initialState = {
  list: {},
};

const ShowinsReducer = function (state = initialState, action) {
  switch (action.type) {
    case "SHOWINGS_INDEX":
      return { ...state, list: action.payload };
    default: {
      return state;
    }
  }
};
export default ShowinsReducer;
