import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Form, Header, Pagination, Statistic, Table } from "semantic-ui-react";
import { AdminsDropdown } from "../../components";
import { StatisticAction } from "../../redux/actions";
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { DROPDOWN_OPTIONS } from "../../constants";


const StatisticTable = () => {
    const [params, setParams] = useState({
        page_size: 10
    })

    useEffect(() => {
        StatisticAction.table(params)
    }, [params])

    const statistic = useSelector(state => state.statistic).table
    console.log(statistic)
    return (
        <>
            <Header
                content="Статистика брокеров"
                subheader="Количество добавленных клиентов и конфликтов"
                icon='table'/>
            <Form>
                <Form.Field>
                    <label>Брокер</label>
                    <AdminsDropdown
                        role="all"
                        onChange={(broker) => setParams({...params, broker, page: 1})}
                        value={params.broker}/>
                </Form.Field>
                <Form.Group widths={'equal'}>
                        <Form.Field>
                            <label>С какой даты</label>
                            <DatePicker 
                                isClearable
                                dateFormat={"dd.MM.yyyy"}
                                selected={params.from_date} 
                                onChange={(date) => setParams({...params, from_date: date, from_date_str: date && moment(date).format('DD.MM.yyyy'), page: 1})} />
                        </Form.Field>
                        <Form.Field>
                            <label>По какую дату</label>
                            <DatePicker 
                                isClearable
                                dateFormat={"dd.MM.yyyy"}
                                selected={params.to_date} 
                                onChange={(date) => setParams({...params, to_date: date, to_date_str: date && moment(date).format('DD.MM.yyyy'), page: 1})} />
                        </Form.Field>
                    </Form.Group>
            </Form>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Брокер</Table.HeaderCell>
                        <Table.HeaderCell>К-во клиентов</Table.HeaderCell>
                        <Table.HeaderCell>К-во конфликтов (вор)</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        statistic.results && statistic.results.map(row => 
                            <Table.Row key={row.id}>
                                <Table.Cell>{row.name}</Table.Cell>
                                <Table.Cell>{row.clients}</Table.Cell>
                                <Table.Cell>{row.conflicts}</Table.Cell>
                            </Table.Row>
                            )
                    }
                </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell>
                                <Dropdown
                                    selection
                                    onChange={(e, {value}) => setParams({...params, page_size: value})}
                                    value={params.page_size}
                                    options={DROPDOWN_OPTIONS}/>
                            </Table.Cell>
                            <Table.Cell textAlign='right' colspan={2}>
                                {
                                    params.page_size > 0 &&
                                    <Pagination
                                        onPageChange={(page, {activePage}) => setParams({...params, page: activePage})}
                                        totalPages={Math.ceil(statistic.count / params.page_size)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                        
                    </Table.Footer>
            </Table>
        </>
    )
}
export default StatisticTable